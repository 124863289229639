.item {
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-color: #f7f7f7;
	padding: 5px 0px;
}

.item:nth-child(even) {
	background: #fcfcfc;
}

.itemSmlCtn {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.starIcon {
	font-size: 1.2rem;
	color: orange;
	padding-right: 28px;
}

.starIcon:hover {
	cursor: pointer;
}

.folderIcon {
	color: rgb(34, 98, 208);
	/* margin-right: 10px; */
}

/*file name */
.documentLink {
	text-align: left;
	text-decoration: none;
	font-size: 0.9rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	color: #737373;
	padding-left: 6px;
}

.dropDownCtn {
	position: relative;
	align-self: center;
	display: flex;
	justify-content: space-between;
	padding: 0 5px;
	word-break: break-word;
}

.dropDownCtn > button {
	background: transparent;
	border: none;
	margin-right: 20px;
}

.dropDownCtn > button:hover {
	color: rgb(34, 130, 208);
	cursor: pointer;
}

.dropDown {
	width: 150px;
	height: auto;
	background-color: white;
	position: absolute;
	z-index: 1;
	right: 0px;
	top: 30px;
	border-radius: 10px;
	-webkit-box-shadow: 0px 5px 29px 1px rgba(0, 0, 0, 0.33);
	-moz-box-shadow: 0px 5px 29px 1px rgba(0, 0, 0, 0.33);
	box-shadow: 0px 5px 29px 1px rgba(0, 0, 0, 0.33);
	display: none;
	padding: 10px;
}

.dropDown.active {
	display: flex;
	flex-direction: column;
}

.deleteBtn,
.renameBtn,
.copyBtn,
.moveBtn {
	margin: 2px;
	background-color: rgb(236, 236, 236);
	font-size: 0.75rem;
	padding: 6px;
}

.dropDown .deleteBtn,
.dropDown .renameBtn,
.dropDown .copyBtn,
.dropDown .moveBtn {
	border: none;
	height: 30px;
}

.dropDown .deleteBtn:focus,
.dropDown .renameBtn:focus,
.dropDown .copyBtn:focus,
.dropDown .moveBtn:focus {
	outline: none;
}

.dropDown .deleteBtn:hover,
.dropDown .renameBtn:hover,
.dropDown .copyBtn:hover,
.dropDown .moveBtn:hover {
	background-color: rgb(99, 160, 211);
	cursor: pointer;
	color: #fff;
}

.documentLink:hover {
	cursor: pointer;
}

.metaData,
.modified {
	font-size: 0.8rem;
	width: 20%;
}
.wrapper-grid {
	padding: 5px;
	display: grid;
	align-content: center;
    grid: 125px auto / 220px;
  }
@media only screen and (max-width: 580px) {
	.starIcon {
		margin-left: 7px;
	}
}
