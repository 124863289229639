footer {
    width: 100%;
    height: 5%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
}

footer > p {
    color: #106BAC;
    font-size: 0.6rem;
    margin: 0;
}

@media only screen and (max-width: 580px) {
    footer > p {
        font-size: 0.5rem;
    }
 }

