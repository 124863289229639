.menu_list > ul  {
    text-align: left;
    padding-left: 30px;
    margin: 50px 0 0 0;
    line-height: 35px;
    font-size: 14px;
    color: #1293D6;
}

.menu_list > ul > li:hover, .menu_list > ul > li > label:hover {
    cursor: pointer;
    color: #106BAC;
}

.menu_list.left > ul {
    margin-left: 80px;
}


    .menu_list > ul  {
        padding: 5px 15px;
        margin-top: 10px;
        border-radius: 5px;
    }

    .menu_list > ul > li {
        border: none;
        background-color: rgb(236, 236, 236);
        font-size: 14px;
        color: #1293D6;
        margin: 5px 0;
        border-radius: 5px;
        padding: 0 10px;
    }

    .menu_list > ul > li:hover , .menu_list > ul > li > label:hover{
        background-color: rgb(99, 160, 211);
        cursor: pointer;
        border-radius: 5px;
        color: #fff;
    }


