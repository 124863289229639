@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');


h1, p, h2 ,h3, h4, li, ul, button, nav, input {
  font-family: "Open Sans", sans-serif;
}

li {
  list-style: none;
}

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

  ::-webkit-scrollbar {
    width: 8px;
}
 
::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #bcbab6; 
}
