main {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #737373;
}

.titleBar {
    width: 100%;
    display: flex;
    align-items: center;
    /* height: 7%; */
}

.tabsCtn {
    width: 52%;
    display: flex;
    height: 100%;
}

.tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    margin-right: 10px;
    border-radius: 15px 15px 0px 0px;
    cursor: pointer;
    color: #737373;
    background-color: rgb(241, 241, 241);
}

.tabs>p {
    margin-right: 5px;
    font-size: 1rem;
    margin-top: 16px;
}

.tagCtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 30%;

}

.metaTag,
.modifiedTag {
    width: 226px;

}

.modifiedTag {
    text-align: right;
}

.metaTag {
    text-align: left;
}


.mainArea>main>ul {
    width: 100%;
    padding: 0;
    font-size: 0.9rem;
    margin: 0;
    height: calc(93% - 40px);
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #F7F7F7;
}

@media only screen and (max-width: 720px) {
    .tagCtn {
        display: none;
    }

    .tabsCtn {
        width: 80%;
    }
}

.grid-icon {
    display: flex;
    justify-content: end;
    /* margin-right: 60px; */
    width: 48%;
}

.grid-section {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.grid-options {
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(1379px, 182px);
}

.popover-grid {
    display: flex;
    flex-direction: column;
}

.popover-grid>span {
    border: none;
    background-color: rgb(236, 236, 236);
    font-size: 14px;
    color: #1293D6;
    margin: 2px 0;
    border-radius: 5px;
    padding: 0 10px;
}

.popover {
    border: none !important;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 29px 1px rgb(0 0 0 / 33%);
    border-radius: 10px;
}

.popover> :hover span:hover {
    background-color: rgb(99, 160, 211);
    cursor: pointer;
    border-radius: 5px;
    color: #fff;
}

.back-login {
    margin-right: 20%;
    margin-left: 20%;
    margin-top: 15px;
}