/*Log In Css */ 

.container-login {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
}

.logo-login {
    width: 200px;
    margin: 50px 0 0 100px;
    z-index: 3;
}

.cloud-bg {
    position: absolute;
    bottom: 0;
    right: -2px;
    width: 75%;
    z-index: 1;
}

.cloud-img {
    position: absolute;
    bottom: 10%;
    right:10%;
    width: 40%;
    z-index: 2;
}

.container-login > h1 {
    font-size: 3rem;
    color: #106BAC;
    font-weight: 600;
    margin: 70px 0 0 100px;
    z-index: 3;
}

.container-login > p {
    font-size: 0.9rem;
    color: #106BAC;
    margin: 20px 0 0 100px;
    width: 35%;
    text-align: left;
    line-height: 30px;
    z-index: 3;
}

.container-login > a {
    padding: 15px 0;
    background-color: #F5A012;
    border-radius: 25px;
    width: 250px;
    font-size: 16px;
    text-decoration: none;
    color: #ffffff;
    text-transform: uppercase;
    margin: 30px 0 0 100px;
    z-index: 3;
}

.container-login > a:hover {
    cursor: pointer;
    background-color: rgb(235, 104, 28);
    color: #ffffff;
}


@media only screen and (min-width: 1500px) {

    .container-login > h1 {
        font-size: 3vw;
        margin: 250px 0 0 100px;

    }

    .container-login > p {
        font-size: 1vw;
        line-height: 1.5vw;
    }
  }


@media only screen and (max-width: 720px) {

    .container-login > h1 {
        margin: 50px 0 0 60px;
        font-size: 2rem;

    }

    .container-login > p {
        font-size: 1rem;
        line-height: 1.8rem;
        margin: 20px 0 0 60px;
        width: 80%;
    }

    .container-login > a {
        margin: 50px 0 0 60px;
    }

    .logo-login {
        margin: 30px 0 0 60px;
    }

    .cloud-bg {
        bottom: -25px;
        width: 100%;
    }

    .cloud-img {
        bottom: 5%;
        right:10%;
        width: 55%;
        z-index: 2;
    }

  }

  @media only screen and (max-width: 414px) {
    .logo-login {
      margin: 50px 0 0 30px;
      width: 47%;
    }

    .container-login > h1 {
      margin: 20px 0 0 30px;
      font-size: 2.1rem;
  }

  .container-login > p {
      font-size: 0.9rem;
      line-height: 1.5rem;
      margin: 20px 0 0 30px;
      width: 80%;
  }

  .container-login > a {
      margin: 50px 0 0 30px;
      padding: 10px;
      width: 150px;
  }
}

  @media only screen and (max-width: 375px) {
      .logo-login {
        margin: 40px 0 0 30px;
        width: 50%;
      }

      .container-login > h1 {
        margin: 20px 0 0 30px;
        font-size: 1.8rem;
    }

    .container-login > p {
        font-size: 0.8rem;
        line-height: 1.4rem;
        margin: 20px 0 0 30px;
        width: 80%;
    }

    .container-login > a {
        margin: 50px 0 0 30px;
        padding: 10px;
        width: 150px;
    }
  }


